import React, {useState, useEffect, useRef, useContext} from 'react';
import { DateTime } from "luxon";
import Message from "../Message";
import {MessageNoteContext} from "../../../context/messageNoteContext";
import DateRange from '../DateRange/DateRange';
import {MessageContext} from "../../../context/messageContext";
import {CompanyContext} from "../../../context/companyContext";

import './MessageList.scss';

const ConversationView = (props) => {

    const [loading, setLoading] = useState(true);
    const [messagesList, setMessagesList] = useState([]);
    const findMessageRef = useRef(null);
    const findMessageContextRef = useRef(null);
    const {selectedMessageNote, foundMessage, setFoundMessage} = useContext(MessageNoteContext);
    const audioRefs = useRef([]);
    const {selectedMessageContext, foundMessageContext, loadingMessages,
        selectedForwardMessages, forward,
        updateFoundMessageContext, updateSelectedForwardMessages, isSearchingContext,
        hasScrolledInSearchedMessages, updateHasScrolledInSearchedMessages} = useContext(MessageContext)

    const { providerTimezone } = useContext(CompanyContext)
    const highlightMessageRef = useRef(null);
    const [loadStatus, setLoadStatus] = useState(0);

    useEffect(() => {
        if (props?.lastMessageRef?.current){
            props.lastMessageRef.current.value = '';
        }
    }, [])

    useEffect(() => {
        renderMessages(props.messages);
        setLoading(false)
    }, [props.messages, props.client, selectedMessageNote, selectedMessageContext, selectedForwardMessages])

    useEffect(() => {
        if (!props.onStar && props?.lastMessageRef?.current && !foundMessage && selectedMessageNote === null && !foundMessageContext && findMessageContextRef.current === null && !forward){
            if (props.page === 1 && props.scrollAtBottom) setTimeout(() => props.lastMessageRef.current?.scrollIntoView(true), 50);
        }
    }, [props.lastMessageRef, messagesList, props.client, findMessageContextRef, forward])

    useEffect(() => {
        if (findMessageRef.current && foundMessage){
            findMessageRef.current?.scrollIntoView({behavior: 'smooth'});
            setFoundMessage(false);
        }
    }, [selectedMessageNote, props.messages, props.client, foundMessage, findMessageRef]);

    useEffect(() => {
        if (findMessageContextRef.current && !foundMessageContext){
            if (findMessageContextRef.current?.parentNode.previousSibling !== null){
                findMessageContextRef.current?.parentNode.previousSibling.scrollIntoView({ behavior: 'smooth' })
            } else {
                findMessageContextRef.current?.parentNode.scrollIntoView({ behavior: 'smooth' })
            }
            updateFoundMessageContext(false)
        }
    }, [selectedMessageContext, props.messages, props.client, foundMessageContext, findMessageContextRef, loadingMessages]);

    useEffect(() => {
        if (props.client.message_id != null && hasScrolledInSearchedMessages?.message_id !== props.client.message_id && messagesList.length > 0){
            updateHasScrolledInSearchedMessages({
                message_id: props.client.message_id,
                more_status: false,
                not_found: null
            })
        }
        if (isSearchingContext && props.client.message_id != null && highlightMessageRef.current != null && !hasScrolledInSearchedMessages?.more_status){
            const scrollToHighlightedMessage = () => {
                const highlightedElement = document.getElementById(`message-${props.client.message_id}`);
                if (highlightedElement) {

                    let behavior_status = loadStatus === 0 ? 'auto' : 'smooth';

                    setTimeout(() => {
                        highlightedElement.scrollIntoView({
                            behavior: behavior_status,
                            block: 'center'
                        });

                        setLoadStatus(1);

                        highlightedElement.classList.remove('message-highlight', 'fade-out');
                        requestAnimationFrame(() => {
                            highlightedElement.classList.add('message-highlight');
                            setTimeout(() => {
                                highlightedElement.classList.add('fade-out');
                            }, 2000);
                        });
                    }, 100);
                }
            };

            scrollToHighlightedMessage();
            highlightMessageRef.current = null;
            updateHasScrolledInSearchedMessages({
                ...hasScrolledInSearchedMessages,
                more_status: true,
                not_found: false
            })
        } else if (isSearchingContext &&
            highlightMessageRef.current == null &&
            props.client.message_id != null &&
            messagesList.length > 0 &&
            props.client.message_id == hasScrolledInSearchedMessages?.message_id) {
            updateHasScrolledInSearchedMessages({
                ...hasScrolledInSearchedMessages,
                not_found: true
            })
        }
    }, [props.client, messagesList, hasScrolledInSearchedMessages?.message_id]);

    const onMessageForwardChange = (e) => {
        let _selectedForwardMessages = [...selectedForwardMessages];
        if (e.checked) {
            _selectedForwardMessages.push(e.value);
        }

        else {
            for (let i = 0; i < _selectedForwardMessages.length; i++) {
                const selectedForwardMessage = _selectedForwardMessages[i];

                if (selectedForwardMessage === e.value) {
                    _selectedForwardMessages.splice(i, 1);
                    break;
                }
            }
        }
        updateSelectedForwardMessages(_selectedForwardMessages)
    }

    const renderMessages = (msgs) => {
        let tempMessages = []
        let tempDate = []
        let msgsReverse = [...msgs].reverse()

        msgsReverse.map((message)=>{
            if(!tempDate.some((element)=> DateTime.fromISO(element.created).toFormat('dd/LL/yy') === DateTime.fromISO(message.created).toFormat('dd/LL/yy'))){
                tempDate.push(message);
            }
        })

        msgs.map((message, index) => {
            if ((message.recipient === props.contactSelected?.value) || (message.sender === props.contactSelected?.value)){
                let body = message.body

                let isMine = message.type === 'sent';

                const templateMessage = {
                    id: message.id,
                    author: message.client_id,
                    author_name: message.client?.firstname,
                    message: body,
                    created: message.created,
                    username: isMine ? (message.user !== null ? message.user.username : null) : null,
                    failed: message.failed,
                    mimetype: message.mime_type,
                    emoji: message.emoji,
                    message_type: message.message_type,
                    note_reminder: message.note_reminder,
                    sent: message.sent,
                    read: message.read,
                    delivered: message.delivered,
                    message_out: message.type === 'sent',
                    parent: message.parent ? message.parent : null,
                    reaction: message.reaction,
                    reactions: message.reactions,
                    forwarded: message.forwarded,
                    message_media_id: message.message_media_id,
                    referrals: message.referrals,
                    captions: message.captions,
                    emoji_parent: message.emoji_parent,
                    interactive_options: message.interactive_options,
                    message_state: message?.message_state
                }

                let previous = msgs[index - 1];

                let next = msgs[index + 1];
                let currentMoment = DateTime.fromISO(message.created);
                let prevBySameAuthor = false;
                let nextBySameAuthor = false;
                let startsSequence = true;
                let endsSequence = true;
                let showTimestamp = true;

                let messageRef = null;

                if (previous) {
                    let previousMoment = DateTime.fromISO(previous.created);
                    let previousDuration = currentMoment.diff(previousMoment, 'hours');

                    prevBySameAuthor = previous.client_id === message.client_id;

                    if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                        startsSequence = false;
                    }

                    if (previousDuration.as('hours') < 1) {
                        showTimestamp = false;
                    }

                    if (prevBySameAuthor && previousDuration.hours < 1) {
                        startsSequence = false;
                    }

                    if (previousDuration.hours < 1) {
                        showTimestamp = false;
                    }
                }

                if (next) {
                    let nextMoment = DateTime.fromISO(next.created);
                    let nextDuration = nextMoment.diff(currentMoment, 'hours');
                    nextBySameAuthor = next.client_id === message.client_id;

                    if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                        endsSequence = false;
                    }
                    if (nextBySameAuthor && nextDuration.hours < 1) {
                        endsSequence = false;
                    }
                }

                if (message.id === props.client.message_id) {
                    messageRef = highlightMessageRef;
                } else if (props.page === 1 && props.findMessageModal && selectedMessageNote === message.id){
                    messageRef = null
                } else if (props.page === 1 && index === 0){
                    messageRef = props.lastMessageRef
                } else {
                    if (props.page > 1){
                        if (index === (props.messagesPerPage * props.page) - props.messagesPerPage - 1){
                            messageRef = props.lastMessageRef
                        }
                    }
                }

                let findMessage = message.id === selectedMessageNote ? findMessageRef : (message.id === selectedMessageContext ? findMessageContextRef : null)
                tempMessages.unshift(
                    <div key={message.id} ref={messageRef}>
                        <div
                            key={message.id}
                            id={`message-${message.id}`}
                            className={props.client.message_id === message.id ? 'message-container highlighted' : 'message-container'}
                        >
                        <div ref={findMessage}>
                            <span>{tempDate.some((element)=> element.id === message.id) ? <DateRange date={message.created} timezone={providerTimezone} id={message.id}></DateRange> : null}</span>
                            <Message
                                key={message.id}
                                index={index}
                                isMine={isMine}
                                startsSequence={startsSequence}
                                endsSequence={endsSequence}
                                showTimestamp={showTimestamp}
                                data={templateMessage}
                                onMessageForwardChange={onMessageForwardChange}
                                animated={message.id === selectedMessageContext}
                                audioRefs={audioRefs}
                            />
                            </div>
                        </div>
                    </div>
                );
                return tempMessages
            }
        });
        setMessagesList(tempMessages);
    }

    if (loading){
        return null;
    }
    return messagesList;

}

export default ConversationView;